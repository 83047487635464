export const environment = {
  envName: 'staging',
  production: false,
  baseUrl: 'https://den1.serviceo.me',
  messengerBaseUrl: 'http://messenger.staging.serviceo.me',
  messengerApiUrl: 'http://54.164.137.96:4001/',
  apiVersion: 'api/v1',
  googleMapKey: 'AIzaSyCPgeXNOHOu8A5XTu3rCkoivQRHJHkD0LA',
  osmMapKey: 'pk.eyJ1IjoiZ2F1cmF2bGFtYmhhIiwiYSI6ImNrYXhlcnFmZzA0Mmkyc252a21qZjl5cmYifQ.Resf0dshcGXNBekmsfHN0A',
  websocket: 'wss://serviceo-alert-ms.azurewebsites.net',
  rtUrl: 'https://rt.staging.serviceo.me',
  communityUrl: 'https://app.qa.serviceo.me/',
  mspUrl:'http://msp.qa.serviceo.me/',
  gTag_msp: 'G-B6C4EK5P1R',
  gTag_community: 'G-4ZE8S2JP8L',
  notificationUrl: 'https://notify.staging.serviceo.me',
  surveyURL: 'http://survey.serviceo.me',
  jobBoardServiceUrl: 'https://jobboard.staging.serviceo.me', 
  surveyToken: '0ZVr8HDFekxAPb5PRZn6JsM1lxrGlg5E',
  metaDataServiceUrl :'https://metadata.staging.serviceo.me/',
  auth: {
    CLIENT_ID: 'CPdkXo88zbMqfKjtp7s9hHqzrLMEKM92',
    CLIENT_DOMAIN: 'serviceo-staging.auth0.com',
    AUDIENCE: 'https://den1.serviceo.me/api/v1/',
    REDIRECT: 'https://msp.qa.serviceo.me/callback',
    LOGOUT_URL: 'https://msp.qa.serviceo.me',
    SCOPE: 'openid email profile app_metadata'
  },
  fileUpload: {
    maxFileSize: 20971520, //maximum file size allowed for upload (20 MB)
    maxFileLimit: 5
  },
  microsoftAccountId: '0011a000005AHhxAAG',
    fseQLMRecordType: '0122L000000RRi8QAG',
    fteQLMRecordType: '0122L000000RRi9QAG',
    mvvpQLMRecordType: '0122L000000RRiAQAW',
    vcsqdQMRecordType: '0122L000000RRi3QAG',
    vmsAccountRecordTypeId: '0121a000000QaUYAA0',
    vendorDrugTestRecordTypeId: '0122L000000VoSxQAK',
    vendorBgvRecordTypeId: '0122L000000RRZUQA4',
    ironDrugTestRecordTypeId: '0122L000000RRZZQA4',
    ironStandardBgvRecordTypeId: '0122L000000VoSsQAK',
    ironStandardBgvDrugRecordTypeId: '0124z000000tIK3AAM',
    projectRoutingProfileRecordTypeId: '0121a0000006RFeAAM',
    candidateRecordTypeId: '0121a000000eSVHAA2',
    applicationRecordTypeId: '0121a000000eSVEAA2',
    programId : 'a1x2L000001oQbzQAE',
    resouceRecordType:'0122L000000RRmGQAW',
    quizRecordType:'0122L000000RRmFQAW',
    csatLearningAssignmentId: 'aA62L000000PB3fSAG',
    ftePurchaseInvoice: '0121a0000006SEpAAM',
    fsePurchaseInvoice: '0121a0000006QpPAAU',
    magicLinkAccountId: '0011a00000bGvDbAAK',
    pmsRecordTypeId: '0121a000000VllFAAS',
    billyContactSfdcId: '0031a000003syxyAAA',
    channelAccountId: '0011a000005AIU2AAO',
    channel1To1ProjectId: 'a6f4z000000sd1fAAA',
    channel1ToMProjectId: 'a6f4z000000sd1kAAA',
    channel1To1MilestonesId: 'a6c4z000003reaMAAQ',
    channel1ToMMilestonesId: 'a6c4z000003reaRAAQ',
    interviewFeedbackRecordTypeId: '0122L000000VoaDQAS',
    contactRecordTypeId: '0121a000000eSV7AAM',
    hideActivityFeed : true,
    hideNotification : false,
    postNewConversation: {
        Original_Post__c: true,
        Title__c: '',
        Message__c: 'Auto Update: Queries for Job Order',
        taskId: '',
        Addressed_To_Member__c: [],
        Addressed_to_Group__c: ['a6S1a0000008UkcEAE'],
        Addressed_to_Account__c: []
    },
    excludeMagicLink:["00102000003yCcqAAE", "0011a00000bGvDbAAK"],
    facebookAccountSfdcId: '0011a00000pi6B3AAI',
    publicTrainingRecordTypeId: '0124z000000tIHdAAM',
    privateTrainingRecordTypeId: '0124z000000tIHYAA2',
    newExpenseBeginDate: '2022-02-18T00:00:00.000Z',
    selectedAccount: ["0011a000005AIU2AAO", "0012L00000yV8FiQAK"],
    helpdeskProjects: {
        IT: {
            sfdcId: 'a6f2L0000004HY7QAM',
            milestone: 'a6c2L000000CzEjQAK',
            milestoneOwner: 'a2y2L000006cyp9QAA',
            groups: ['a6S2L000000IKQDUA4']
        },
        serviceo: {
            sfdcId: 'a6f2L000000XZDQQA4',
            milestone: 'a6c2L000000br6nQAA',
            milestoneOwner: 'a2y4z000003nIVsAAM',
            groups: ['a6S4z000003Y9MxEAK', 'a6S1a0000008SnKEAU']
        },
        isgPortal: {
            sfdcId: 'a6f1a00000004WVAAY',
            milestone: 'a6c4z000003t1GEAAY',
            milestoneOwner: 'a2y2L000008OPX8QAO',
            groups: ['a6S4z000003Y9MxEAK', 'a6S1a0000008SnKEAU']
        },
        jobOrderDesk: {
            sfdcId: 'a6f2L0000009dxeQAA',
            milestone: 'a6c2L0000000C3jQAE',
            milestoneOwner: 'a2y1a000006EHvvAAG',
            groups: ['a6S2L000000P6wtUAC']
        },
        hrIndia: {
            sfdcId: 'a6f2L000000Gop9QAC',
            milestone: 'a6c2L0000009Tu9QAE',
            groups: ['a6S4z000003Y9wgEAC']
        },
        hrUsa: {
            sfdcId: 'a6f2L000000GookQAC',
            milestone: 'a6c2L0000009Tu9QAE',
            groups: ['a6S2L000000PaWFUA0']
        }
    },
    taskRecordTypeId: '0121a000000F23QAAS',
    subTaskRecordTypeId: '0121a000000F23PAAS',
    indiaExpenseIronApproval:'a6S8I000000CgtlUAC',
    customerExpenseApproval:'a6S8I000000CgtqUAC',
  expenseGBILApproval:'a6R1a0000008SFvEAM',
  expenseGHRISApproval:'a6R1a000000TTTSEA4',
  expenseGFINandGBILGroups: ["a6R1a0000008SFvEAM", "a6R2L000000LqS7UAK"],
  trainingPlanReviseTimeTemplateURL:'https://ironsystems--fullsb.sandbox.my.salesforce-sites.com/PMS/apex/TrainingPlanReviseTimeTemplatePage',
  groupGSTLUMAT0BB :'a6S4z000003YADmEAO',
  pmsRSDMurl: 'https://ironsystems--fullsb.sandbox.my.salesforce-sites.com/PMS/apex/',
  myWorkerAvailabilityUrl: 'https://ironsystems--fullsb.sandbox.my.salesforce-sites.com/PMS/Worker_MyAvailability',
  compensatoryLeaveType :'aBx7e000000PBtFCAW'
};
